import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
// import Menu from "../components/Services/ContentMenu"
import BaseLayout from "../components/global/BaseLayout";
import Busqueda from "../components/Services/Busqueda";

function Services() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Menu">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Menu"
          Subheader="Menu"
          bgimg={`${rpdata?.stock?.[11]}`}
        />
        {/* <Menu/> */}
        <Busqueda/>

      </div>
    </BaseLayout>
  );
}

export default Services;
