import React, { useContext, useState } from 'react'
import { GlobalDataContext } from '../../context/context'
import CardProductBusqueda from './CardProductBusqueda'
import { ButtonContent } from '../global/boton/ButtonContent'




const Busqueda = ({ btnMore, title, searchHome }) => {
    const { rpdata } = useContext(GlobalDataContext)

    const [cardProduct, setCardProduct] = useState('')

    const [search, setSearch] = useState('')


    //select opcion
    const handleSelectChange = (e) => {
        setCardProduct(e.target.value)
        setSearch('')
    }

    //funcion de busqeda
    const searcher = (e) => {
        setSearch(e.target.value)
        setCardProduct('')
        // console.log(e.target.value)
    }


    const resultFilter = !search ? rpdata?.works : rpdata?.works?.filter((datos) => datos?.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))



    return (
        <div className='fondoMenu'>

            <div className='h-full w-4/5 mx-auto relative'>

                <div className='flex flex-col justify-center items-center pt-20 pb-10'>
                    <h1 className='text-center text-[#ff1a1a]'>
                        {
                            title ? title : 'Our Menus'
                        }
                    </h1>

                </div>

                <div>
                    <div className="flex flex-col-reverse md:flex-row md:gap-0 gap-5  pb-10">

                        {
                            searchHome ?
                                <div className='hidden md:block flex-shrink-0 z-10 px-4 text-sm font-medium text-start text-white bg-red-600 border border-gray-300 rounded-lg md:rounded-l-lg md:rounded-r-none'>
                                    <p>Our Menus</p>
                                </div>
                                :
                                <select
                                    className={` flex-shrink-0 z-10 py-2.5 px-4 text-sm font-medium text-start text-white bg-red-600 border border-gray-300 rounded-lg md:rounded-l-lg md:rounded-r-none hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-gray-100  ${!search ? 'block' : 'hidden md:inline-flex'}`}
                                    onChange={handleSelectChange}
                                >
                                    <option
                                        selected
                                        // disabled
                                        className='capitalize'
                                    // defaultValue={'See All '}
                                    >
                                        See All 
                                    </option>
                                    {
                                        rpdata?.dbServices?.map((item, index) => {
                                            return (
                                                <option
                                                    key={index}
                                                    defaultValue={item.name}
                                                    className='capitalize'
                                                >
                                                    {item.name}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                        }



                        <div className="relative w-full">
                            <input
                                type="search"
                                className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg md:rounded-r-lg md:rounded-l-none border-l-gray-50 border-l-2 border border-gray-300 focus:ring-red-500 focus:border-red-500"
                                placeholder="Search For Your Best Antogitos..."
                                required
                                value={search}
                                onChange={searcher}
                            />
                            <div
                                type="submit"
                                className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-red-700 rounded-r-lg border border-red-700 focus:ring-4 focus:outline-none focus:ring-red-300"
                            >
                                <svg
                                    aria-hidden="true"
                                    className="w-5 h-5"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                </svg>
                                <span className="sr-only">
                                    Search
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex h-full'>
                    <div className='w-full h-full border bg-[#ffac30ab] rounded-lg py-10 mb-20'>
                        <div className='w-full h-full  flex justify-center flex-wrap gap-5'>
                            {
                                cardProduct === 'See All' || cardProduct === '' ?

                                    resultFilter?.slice(0, searchHome ? 8 : resultFilter.length)?.map((item, index) => {
                                        return (

                                            <CardProductBusqueda
                                                key={index}
                                                imagen={item.gallery?.[0]}
                                                name={item.name}
                                                price={item.description}
                                                phone={rpdata?.dbPrincipal?.phones?.[0]?.phone.replaceAll("-", "")}
                                            />


                                        )
                                    })
                                    :
                                    <div className='flex justify-center flex-wrap gap-5'>
                                        {
                                            // eslint-disable-next-line array-callback-return
                                            rpdata?.works?.map((item, index) => {
                                                const expert = item?.service;
                                                switch (cardProduct) {
                                                    case `${expert}`:
                                                        return (

                                                            <CardProductBusqueda
                                                                key={index}
                                                                imagen={item.gallery?.[0]}
                                                                name={item.name}
                                                                price={item.description}
                                                                phone={rpdata?.dbPrincipal?.phones?.[0]?.phone.replaceAll("-", "")}
                                                            />
                                                        )
                                                    default:
                                                    // do nothing
                                                }
                                            })
                                        }
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='flex justify-center items-center pb-20 -mt-10'>
                    {
                        btnMore ?
                            <ButtonContent btnName={'View All Menus'} btnLink={'menu'} />
                            : null
                    }
                </div>
            </div>
        </div>
    )
}

export default Busqueda