import React from 'react'
import { BsArrowBarRight } from 'react-icons/bs'


const CardProductBusqueda = ({ imagen, name, price, phone }) => {
    return (
        <div className='w-[250px] text-center shadow-lg pb-5 rounded-lg bg-[#fff]'>
            <div className='h-[250px] w-full pb-5'>
                <img
                    src={imagen}
                    alt="not images"
                    className='object-cover w-full h-full rounded-t-lg'
                />
            </div>
            <div className='px-4'>
                <div className='flex justify-between pb-5'>
                    <p className='font-bold'>{name}</p>
                    <p className='font-bold text-red-500'>$ {price}</p>
                </div>

                <a
                    type='button'
                    href={`https://wa.me/1${phone}`}
                    className='flex items-center justify-end gap-3 font-bold hover:text-red-600 ease-in-out duration-300'
                >
                    <span>Order Now</span>
                    <BsArrowBarRight />
                </a>
            </div>

        </div>
    )
}

export default CardProductBusqueda